<template>
  <div class="container-fluid">
    <div class="card nw-card mt-2">
      <div class="card-header nw-card-header text-left">
        <h4>Survey List</h4>
      </div>
      <div class="card-body text-left">
        <button class="btn nw-btn-outline-info"><i class="fa fa-plus"></i> Create New Survey<br/></button>
        <table class="table table-hover mt-4">
          <thead><tr><th>Survey Name</th><th>Enabled</th><th>Repeatable</th><th>Retail</th><th>Clinical</th></tr></thead>
          <tbody>
            <tr v-for="item in arr" @click="select(item.oid)">
              <td>{{ item.name}}</td>
              <td>{{ item.enabled ? 'Yes' : 'No'}}</td>
              <td>{{ item.repeatable ? 'Yes' : 'No'}}</td>
              <td>{{ item.includeReatil ? 'Yes' : 'No'}}</td>
              <td>{{ item.includeClinical ? 'Yes' : 'No'}}</td>
            </tr>
          </tbody>

        </table>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  data () {
    return {
      arr: [
        { name: 'Release Form', enabled: true, includeReatil: true, includeClinical: false, repeatable: false},
        { name: 'Nightmare Disorder Index', enabled: true, includeReatil: true, includeClinical: false, repeatable: true}
      ]
    }
  },
  methods: {
    select (_id) {
      this.$router.push({ name: 'Survey'})
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
